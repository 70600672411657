import {Component, OnDestroy, OnInit} from '@angular/core';
import {TabNavigationService} from './tab-navigation.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {UmsatzFilterService} from '../umsatz-filter/umsatz-filter.service';
import {NavigationService} from '@adnova/jf-ng-components';


@Component({
  selector: 'ku-tab-navigation',
  templateUrl: './tab-navigation.component.html',
  styleUrls: ['./tab-navigation.component.scss']
})
export class TabNavigationComponent implements OnInit, OnDestroy {

  public countedToClarify = 0;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    public navigationService: NavigationService,
    public tabNavigationService: TabNavigationService,
    protected umsatzFilterService: UmsatzFilterService,
  ) {
  }

  ngOnInit(): void {
    this.tabNavigationService.countedToClarify$.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(counted => {
      this.countedToClarify = counted;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
