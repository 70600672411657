<ul *ngIf="tabNavigationService.inhabersLoaded">
  <li>
    <a routerLink="/overview/inhaber/{{(navigationService.currentInhaber$ | async)?.id || ''}}" routerLinkActive="active">
      Übersicht
    </a>
  </li>

  <li>
    <a
      routerLink="/to-assign/inhaber/{{(navigationService.currentInhaber$ | async)?.id || ''}}"
      [queryParams]="umsatzFilterService.pathQueries.get('to-assign')"
      routerLinkActive="active">
      Umsätze zu bearbeiten
    </a>
  </li>

  <li>
    <a
      routerLink="/to-clarify/inhaber/{{(navigationService.currentInhaber$ | async)?.id || ''}}"
      routerLinkActive="active">
      Zu Klären

      <ku-to-clarify-badge *ngIf="countedToClarify > 0" [count]="countedToClarify"></ku-to-clarify-badge>
    </a>

  </li>
  <li>
    <a
      routerLink="/all-assigned/inhaber/{{(navigationService.currentInhaber$ | async)?.id || ''}}"
      [queryParams]="umsatzFilterService.pathQueries.get('all-assigned')"
      routerLinkActive="active">
      Alle Umsätze
    </a>
  </li>
</ul>
