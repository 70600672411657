import {Component, Inject} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';
import {Store} from '@ngrx/store';
import {AppState} from '../../store/states/app.state';
import {InhaberEntitiesActions} from '@adnova/jf-ng-components';


export interface AbrufErrorPopupData {
  message: string,
}

@Component({
  selector: 'ku-abruf-error-popup',
  templateUrl: './abruf-error-modal.component.html',
  styleUrls: ['./abruf-error-modal.component.scss']
})
export class AbrufErrorModalComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AbrufErrorPopupData,
    private store: Store<AppState>,
  ) {
    this.store.dispatch(InhaberEntitiesActions.setRestictRedirect());
  }
}
