import {Injectable, OnDestroy} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {EMPTY, Observable, Subject, takeUntil, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ErrorpageService} from '../services/errorpage.service';
import {Config, ConfigService} from "../config/service/config.service";


/** Pass untouched request through to the next request handler. */
@Injectable()
export class KoasInterceptor
  implements HttpInterceptor, OnDestroy {

  private readonly unsubscribe$ = new Subject<void>();

  private config?: Config;

  constructor(
    private errorpageService: ErrorpageService,
    private configService: ConfigService,
  ) {
    this.configService.loaded$
      .pipe(
        takeUntil(this.unsubscribe$),
      )
      .subscribe(value => {
        this.config = value;
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(error => {
        if (error.status) {
          switch (error.status) {
            case 502:
              this.errorpageService.serviceUnavailable();
              return EMPTY;
              break;
            case 503:
              // Wenn der 503 aus dem Beleg-Service kommt, wird ein separater Handler aufgerufen
              if (error.url.indexOf(this.config?.beleg.apiBaseUrl) >= 0) {
                this.errorpageService.belegServiceUnavailable();
              } else {
                this.errorpageService.serviceUnavailable();
                return EMPTY;
              }
              break;
          }
        }
        return throwError(error);
      })
    );
  }
}
