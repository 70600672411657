import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree} from '@angular/router';
import {AutoLoginAllRoutesGuard} from 'angular-auth-oidc-client';
import {NGXLogger} from 'ngx-logger';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

import {PortalMessageBroker} from '../portal/portal-message-broker';


/**
 * Guard der prüft ob der Nutzer angemeldet ist.<br>
 * Hierbei wird sowohl der Standalone-Login als auch der Login über das Portal unterstützt.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  /**
   * Erstellt einen neuen AuthGuard
   *
   * @param logger Logger
   * @param portalMessageBroker PortalMessageBroker um zu prüfen, ob die App im Portal ausgeführt wird
   * @param autoLoginGuard AutoLoginGuard, an der Standalone-Login delegiert wird
   */
  constructor(
    private logger: NGXLogger,
    private portalMessageBroker: PortalMessageBroker,
    private autoLoginGuard: AutoLoginAllRoutesGuard,
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.checkAuth(route, state);
  }

  /**
   * Prüft bzw. stellt sicher das der Nutzer entweder über das Portal<br>
   * oder im Standalone-Modus angemeldet ist.
   *
   * @param route
   * @param state
   * @private
   */
  private checkAuth(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    // INFO: check for running in portal
    if (this.portalMessageBroker.isRunningInPortal()) {
      this.logger.trace('AuthGuard.checkAuth(): running in portal: ', true);
      // INFO: assume logged in
      return true;
    }

    // INFO: delegate to AutoLoginGuard
    return this.autoLoginGuard.canActivate(route, state)
      .pipe(
        tap(canActivate => {
          this.logger.trace('AuthGuard.checkAuth(): running in standalone: ', canActivate);
        }),
      );
  }
}
