import {Component} from '@angular/core';



@Component({
  selector: 'ku-app-navigation',
  templateUrl: './app-navigation.component.html',
  styleUrls: ['./app-navigation.component.scss']
})
export class AppNavigationComponent {}
