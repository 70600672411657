import {Injectable, OnDestroy} from '@angular/core';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {NGXLogger} from 'ngx-logger';
import {takeUntil} from 'rxjs/operators';
import {UmsatzService as Service} from '../openapi/kontoumsatz-openapi';
import {UmsatzBaseService} from './umsatz-base.service';
import {Observable, Subject, Subscription} from 'rxjs';
import {AbrufErrorModalService} from '../modules/abruf-error-modal/abruf-error-modal.service';
import {Router} from '@angular/router';
import {FetchedUmsaetze} from '../interfaces/fetched-umsaetze.interface';
import {NavigationService} from '@adnova/jf-ng-components';


@Injectable({
  providedIn: 'root'
})
export class UmsatzService extends UmsatzBaseService implements OnDestroy {

  private readonly finishedAssignment$ = new Subject<void>();

  private fetchedUmsaetze: FetchedUmsaetze[] = [];

  constructor(
    private abrufErrorPopupService: AbrufErrorModalService,
    private router: Router,
    logger: NGXLogger,
    umsatzService: Service,
    navigationService: NavigationService,
    snackBar: MatSnackBar,
  ) {
    super(
      logger,
      umsatzService,
      navigationService,
      snackBar,
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe();
    this.finishedAssignment$.complete();
  }

  /**
   * Triggert das Abrufen der Umsätze von der Datendrehscheibe
   *
   * @param inhaberId = Die Id des Inhabers für den dies durchgeführt werden soll
   */
  fetchUmsaetze(
    inhaberId: string,
    errorModalsAllowed: boolean,
  ): Subscription {
    return this.umsatzService.umsaetzeAbrufen(inhaberId)
      .pipe(
        takeUntil(this.unsubscribe$),
      ).subscribe((abrufResults) => {
        const unsuccessfulResults = abrufResults.filter(result => result.ergebnis !== 'Erfolgreich');

        this.startAssignment(inhaberId);

        this.fetchedUmsaetze = [
          ...this.fetchedUmsaetze.filter(umsatz => umsatz.inhaberId !== inhaberId),
          {inhaberId, abrufenResultDTO: abrufResults}
        ];

        // INFO: In Widgets sollen keine Fehlermeldungen angezeigt werden, selbst wenn hier Probleme auftreten
        if (unsuccessfulResults.length > 0 && errorModalsAllowed) {
          this.abrufErrorPopupService.openAbrufErrorPopup(
            'Aufgrund von Wartungsarbeiten der Bank können Kontoumsätze ' +
            'teilweise derzeit nicht aktualisiert werden. ' +
            'Die automatische Zuordnung der vorliegenden Kontoumsätze ' +
            'deiner Bankkonten zu neuen Belegen wird dennoch durchgeführt.'
          );
        }
      }, error => {
        switch (error.status) {
          case 400:
          case 404: {
            this.abrufErrorPopupService.openAbrufErrorPopup();
            break;
          }
          case 423: {
            this.abrufErrorPopupService.openAbrufErrorPopup('Bankkonto durch einen Anwender in ADNOVA+ gesperrt.');
            break;
          }
          default: {
            this.abrufErrorPopupService.openAbrufErrorPopup();
          }
        }
      });
  }

  getFetchedUmsaetze(inhaberId: string, errorModalsAllowed: boolean): FetchedUmsaetze | undefined {
    const alreadyFetched = this.fetchedUmsaetze.find(umsatz => umsatz.inhaberId === inhaberId);
    if (alreadyFetched) {
      return alreadyFetched;
    }

    this.fetchUmsaetze(inhaberId, errorModalsAllowed);
    return;
  }


  /**
   * Triggert das automatische Zuordnen der Umsätze zu den Belegen im Belegbuch
   *
   * @param inhaberId = Die Id des Inhabers für den dies durchgeführt werden soll
   */
  startAssignment(
    inhaberId: string,
  ): void {
    this.umsatzService.startZuordnung(inhaberId)
      .pipe(
        takeUntil(this.unsubscribe$),
      )
      .subscribe(() => {
        if (this.router.url.includes(inhaberId)) {
          this.finishedAssignment$.next();
        }
      }, error => {
        let errorMsg: string;

        switch (error.status) {
          case 400:
          case 404: {
            errorMsg = 'Fehler bei der automatischen Zuordnung von Kontoumsätzen.';
            break;
          }
          case 423: {
            errorMsg = 'Ein Datensatz wurde durch einen Anwender in ADNOVA+ gesperrt.';
            break;
          }
          default: {
            errorMsg = 'Fehler bei der automatischen Zuordnung von Kontoumsätzen.';
          }
        }

        this.snackBar.open(errorMsg, undefined, {duration: 5000, panelClass: 'error'});
      });
  }

  get finishedAssignment(): Observable<void> {
    return this.finishedAssignment$.asObservable();
  }
}
