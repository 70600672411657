import {Injectable} from '@angular/core';
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {AbrufErrorModalComponent} from './abruf-error-modal.component';
import {Store} from '@ngrx/store';
import {AppState} from '../../store/states/app.state';
import {InhaberEntitiesActions} from '@adnova/jf-ng-components';


@Injectable({
  providedIn: 'root'
})
export class AbrufErrorModalService {

  private matDialogRef?: MatDialogRef<AbrufErrorModalComponent>;

  constructor(
    private dialog: MatDialog,
    private store: Store<AppState>,
  ) {
  }

  openAbrufErrorPopup(message = ''): void {
    if (!this.matDialogRef) {
      this.matDialogRef = this.dialog.open(AbrufErrorModalComponent, {
        width: '580px',
        height: '320px',
        autoFocus: false,
        data: {
          message: message,
        }
      });
    }
    this.matDialogRef.afterClosed().subscribe(() => {
      this.store.dispatch(InhaberEntitiesActions.setAllowRedirect());
    });
  }
}
