import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {MatSelect} from '@angular/material/select';
import {BankkontoDTO} from '../../../../openapi/kontoumsatz-openapi';
import {FormControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';


interface SelectOption {
  bankkontoId: string;
  display: string;
};

@Component({
  selector: 'ku-filter-bank',
  templateUrl: './filter-bank.component.html',
  styleUrls: ['./filter-bank.component.scss']
})
export class FilterBankComponent implements OnInit, OnDestroy {

  @ViewChild(MatSelect)
  private select?: MatSelect;

  @Input()
  set value(bankIds: string[] | undefined) {
    this.control.setValue(
      bankIds,
      {emitEvent: false}
    );
  }

  @Input()
  set options(bankkontoDtos: BankkontoDTO[]) {
    this.selectOptions = bankkontoDtos.map(bankkontoDto => ({
      bankkontoId: bankkontoDto.id,
      display: bankkontoDto.bezeichnung || bankkontoDto.iban,
    }));
  }

  @Output()
  valueChange = new EventEmitter<string[]>();

  protected selectOptions: SelectOption[] = [];

  protected control = new FormControl();

  private closeOptionsTimeout!: any;

  private readonly unsubscribe$ = new Subject<void>();

  ngOnInit() {
    this.control.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe((values: string[]) => {
      this.valueChange.emit(values);
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  closeOptions(): void {
    this.closeOptionsTimeout = setTimeout(() => {
      this.select?.close();
    }, 500);
  }

  cancelCloseOptions(): void {
    clearTimeout(this.closeOptionsTimeout);
  }

}
