import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Subject} from 'rxjs';
import {debounceTime, filter, takeUntil} from 'rxjs/operators';
import {DateFilterData} from '../../../../interfaces/date-filter-data.interface';
import * as moment from 'moment/moment';


@Component({
  selector: 'ku-filter-datum',
  templateUrl: './filter-datum.component.html',
  styleUrls: ['./filter-datum.component.scss']
})
export class FilterDatumComponent implements OnInit, OnDestroy {

  private ignoreValueChanges = false;

  @Input()
  public set value(value: DateFilterData | undefined) {
    this.ignoreValueChanges = true;
    this.range.setValue({
      from: value?.from ?? null,
      to: value?.to ?? null,
    }, {emitEvent: false});
    this.ignoreValueChanges = false;
  }

  @Output()
  public valueChange = new EventEmitter<DateFilterData>();

  public range = new FormGroup({
    from: new FormControl<string | Date | undefined>(undefined),
    to: new FormControl<string | Date | undefined>(undefined),
  });

  private readonly unsubscribe$ = new Subject<void>();

  ngOnInit(): void {
    this.range.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      filter(() => !this.ignoreValueChanges),
      debounceTime(250),
    ).subscribe((value => {
      this.valueChange.emit({
        from: this.formatDate(value.from),
        to: this.formatDate(value.to),
      });
    }));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private formatDate(value: any): string | undefined {
    if (value === undefined || value === null) {
      return undefined;
    }
    return moment(value).format('YYYY-MM-DD');
  }
}
