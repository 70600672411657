import {NgModule} from "@angular/core";
import {SnackbarComponent} from "./snackbar.component";
import {IconModule} from "../icon/icon.module";

@NgModule({
  declarations: [
    SnackbarComponent,
  ],
  imports: [
    IconModule
  ],
  exports: [
    SnackbarComponent,
  ]
})
export class SnackbarModule {

}
