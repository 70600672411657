import {Component, OnDestroy, OnInit} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {BankkontoService} from '../../../services/bankkonto.service';
import {NavigationService} from '@adnova/jf-ng-components';


@Component({
  selector: 'ku-bankkonto-not-found',
  templateUrl: './bankkonto-not-found.component.html',
  styleUrls: ['./bankkonto-not-found.component.scss']
})
export class BankkontoNotFoundComponent implements OnInit, OnDestroy {

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    public navigationService: NavigationService,
    private bankkontoService: BankkontoService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.bankkontoService.bankkonten.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(bankkonten => {
      if (bankkonten.length !== 0) {
        // INFO Sobald Bankkonten geladen werden können, auf die Übersicht Seite navigieren.
        this.router.navigate(['/overview/inhaber/', this.navigationService.currentInhaber?.id]);
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  reloadBankkonten() {
    this.bankkontoService.fetchBankkonten();
  }
}
