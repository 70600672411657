import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, combineLatest, concatMap, EMPTY, Subject, Subscription, tap} from 'rxjs';
import {NGXLogger} from 'ngx-logger';
import {catchError, debounceTime, filter, map, startWith, takeUntil} from 'rxjs/operators';
import {ToAssignData} from './to-assign/interfaces/to-assign-data.interface';
import {ToAssignPageableData} from './to-assign/interfaces/to-assign-pageable-data.interface';
import {ToAssignCountData} from './to-assign/interfaces/to-assign-count-data.interface';
import {ToClarifyCountData} from './to-clarify/interfaces/to-clarify-count-data.interface';
import {WidgetHeaderData} from '../widget-header/interfaces/widget-header-data.interface';
import {WidgetInhaberData} from '../widget-header/interfaces/widget-inhaber-data.interface';
import {InhaberDTO, UmsatzService} from '../../../openapi/kontoumsatz-openapi';
import {InhaberEntitiesSelectors, NavigationService} from '@adnova/jf-ng-components';
import {AppState} from '../../../store/states/app.state';
import {Store} from '@ngrx/store';


@Injectable({
  providedIn: 'root',
})
export class UtilityWidgetService implements OnDestroy {

  // INFO: Triggert den Reload der Daten
  public readonly reload$ = new Subject<void>();

  /*
   * INFO: Daten für den Header: Überschrift und Tab-Bezeichnungen,
   * je mit der Info ob der "todoExists"-Punkt angezeigt werden soll.
   */
  private readonly widgetHeaderData$ = new BehaviorSubject<WidgetHeaderData>({
    title: 'Kontoumsätze',
    widgetInhaberData: [],
  });

  // INFO: Ausgewählter Inhaber Tab
  private readonly inhaberId$ = new BehaviorSubject<string | undefined>(undefined);

  // INFO: Daten für den "ToClarify"-Abschnitt: Enthält die Anzahl der zu klärenden Umsätze pro Inhaber.
  private readonly toClarifyCountData$ = new BehaviorSubject<ToClarifyCountData[]>([]);

  // INFO: Daten für den "ToAssign"-Abschnitt: Enthält die Anzahl der zuzuordnenden Umsätze pro Inhaber.
  private readonly toAssignCountData$ = new BehaviorSubject<ToAssignCountData[]>([]);

  // INFO: Daten für den "ToAssign"-Abschnitt: Enthält die zuzuordnenden Umsätze pro Inhaber.
  private readonly toAssignData$ = new BehaviorSubject<ToAssignData | undefined>(undefined);

  // INFO: Daten für den "ToAssign"-Abschnitt: Enthält die zuzuordnenden Umsätze pro Inhaber.
  private readonly toAssignPageableData$ = new BehaviorSubject<ToAssignPageableData[]>([]);

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private logger: NGXLogger,
    private navigationService: NavigationService,
    private umsatzService: UmsatzService,
    private store: Store<AppState>,
  ) {

    this.store.select(InhaberEntitiesSelectors.currentInhaberId).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(inhaberId => {
      this.inhaberId$.next(inhaberId || undefined);
    });

    this.navigationService.inhaberLoaded$.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(0),
    ).subscribe(loaded => {
      this.reload$.next();
    });

    // INFO: Warten, bis die Inhaber geladen wurden.
    combineLatest([
      this.navigationService.inhaberList$,
      this.reload$.pipe(startWith(undefined)),
    ]).pipe(
      takeUntil(this.unsubscribe$),

      // INFO: Initial wird der "todoExists"-Punkt nicht angezeigt
      map(([inhabers]) => inhabers.map(inhaber => ({inhaber, todoExists: false} as WidgetInhaberData))),
      concatMap(widgetInhaberData => {
        return combineLatest([
          this.toClarifyCountData$,
          this.toAssignCountData$,
        ]).pipe(
          debounceTime(100),
          takeUntil(this.unsubscribe$),
          takeUntil(this.reload$),
          map(([toClarifies, toAssigns]) => {
            /*
             * INFO: Wenn sich in toClarifyCountData$ etwas ändert, wird gecheckt, in welchen Inhabern
             * noch etwas zu klären ist. Dort wird dann der Punkt angezeigt.
             */
            for (let toClarify of toClarifies) {
              for (let i in widgetInhaberData) {
                if (widgetInhaberData[i].inhaber.id === toClarify.inhaber.id) {
                  widgetInhaberData[i].todoExists = !!toClarify.toClarify || widgetInhaberData[i].todoExists;
                }
              }
            }

            /*
             * INFO: Wenn sich in toAssignCountData$ etwas ändert, wird gecheckt, in welchen Inhabern
             * noch Umsätze zuzuordnen sind. Dort wird dann der Punkt angezeigt.
             */
            for (let toAssign of toAssigns) {
              for (let i in widgetInhaberData) {
                if (widgetInhaberData[i].inhaber.id === toAssign.inhaber.id) {
                  widgetInhaberData[i].todoExists = !!toAssign.toAssign || widgetInhaberData[i].todoExists;
                }
              }
            }

            return widgetInhaberData;
          }),
        );
      }),
    ).subscribe(widgetInhaberData => {
      this.widgetHeaderData$.next({
        ...this.widgetHeaderData$.value,
        widgetInhaberData,
      });
    });
  }

  // INFO: Unsubscriben bevor die Subscription gestartet wird um beim Inhaber wechsel doppelte Subscriptions zu vermeiden.
  private readToAssignSubscription?: Subscription;

  // INFO: Laden aller zuzuordnenden Umsätze auf der gesetzen Pagination
  public readToAssign() {
    this.readToAssignSubscription?.unsubscribe();

    // INFO: Wenn die Pageables gesetzt, bzw. geändert wurden ...
    this.readToAssignSubscription = combineLatest([
      this.inhaberId$,
      this.toAssignPageableData$,
      this.reload$.pipe(startWith(undefined)),
    ]).pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(100),
      map(([inhaberId, pageables]) => {
        const pageable = pageables.find(pageable => pageable.inhaber.id === inhaberId)?.pageable;
        return {inhaberId, pageable};
      }),
      filter(data => !!data.pageable?.sorting && !!data.pageable.limit && !!data.inhaberId),
      map(data => ({...data, inhaberId: data.inhaberId!})),
    ).subscribe(data => {

      // INFO: ... werden die Umsätze ausgelesen.
      this.umsatzService.readUmsaetze(data.inhaberId, {
          zurKlaerung: false,
          abgeschlossen: false,
        },
        data.pageable,
      ).pipe(
        catchError(error => {
          this.logger.error('Fehler beim Laden der Umsätze', error);
          return EMPTY;
        }),
      ).subscribe(toAssign => {
        if (toAssign.length === 0 && data.pageable?.offset) {
          const currentPageableData =
            this.toAssignPageableData$.value.find(pageable => pageable.inhaber.id === data.inhaberId);

          if (!currentPageableData) return;

          const offset = currentPageableData.pageable.offset || 0;
          const limit = currentPageableData.pageable.limit || 0;
          currentPageableData.pageable.offset = offset - limit;

          const filteredPageableData =
            this.toAssignPageableData$.value.filter(pageable => pageable.inhaber.id !== data.inhaberId) || [];

          this.toAssignPageableData$.next([
            ...filteredPageableData,
            currentPageableData
          ]);
          return;
        }
        this.toAssignData$.next({toAssign});
      });

    });
  }


  // Zählen aller zuzuordnenden Umsätze
  public countToAssign(inhabers: InhaberDTO[]) {
    this.reload$.pipe(startWith(undefined)).subscribe(() => {
      let toAssign = [] as ToAssignCountData[];
      for (let i in inhabers) {
        this.umsatzService.countUmsaetze(inhabers[i].id, {
          zurKlaerung: false,
          abgeschlossen: false,
        }).pipe(
          catchError(error => {
            this.logger.error(error);
            return EMPTY;
          }),
        ).subscribe(count => {
          toAssign.push({inhaber: inhabers[i], toAssign: count.count});
          /*
           * INFO: Wenn der letzte Inhaber gecheckt wurde, können die
           * Daten gesammelt in das BehaviorSubject übertragen werden
          */
          if (toAssign.length === inhabers.length) this.toAssignCountData$.next(toAssign);
        });
      }
    });
  }


  toClarifyCount(inhabers: InhaberDTO[]): void {
    let toClarify = [] as ToClarifyCountData[];
    for (let i in inhabers) {
      this.umsatzService.countUmsaetze(inhabers[i].id, {zurKlaerung: true}).pipe(
        catchError(error => {
          this.logger.error(error);
          return EMPTY;
        }),
      ).subscribe(count => {
        toClarify.push({inhaber: inhabers[i], toClarify: count.count});
        /*
         * INFO: Wenn der letzte Inhaber gecheckt wurde, können die
         * Daten gesammelt in das BehaviorSubject übertragen werden
        */
        if (toClarify.length === inhabers.length) this.toClarifyCountData$.next(toClarify);
      });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get widgetHeaderData(): BehaviorSubject<WidgetHeaderData> {
    return this.widgetHeaderData$;
  }

  get inhaberId(): BehaviorSubject<string | undefined> {
    return this.inhaberId$;
  }

  get toClarifyCountData(): BehaviorSubject<ToClarifyCountData[]> {
    return this.toClarifyCountData$;
  }

  get toAssignCountData(): BehaviorSubject<ToAssignCountData[]> {
    return this.toAssignCountData$;
  }

  get toAssignData(): BehaviorSubject<ToAssignData | undefined> {
    return this.toAssignData$;
  }

  get toAssignPageableData(): BehaviorSubject<ToAssignPageableData[]> {
    return this.toAssignPageableData$;
  }

}
