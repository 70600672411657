import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BankkontoNotFoundComponent} from './bankkonto-not-found/bankkonto-not-found.component';
import {ErrorPagesRoutingModule} from './error-pages-routing.module';
import {IconModule} from '../icon/icon.module';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {BetriebNotFoundComponent} from './betrieb-not-found/betrieb-not-found.component';
import {ServiceUnavailableComponent} from './service-unavailable/service-unavailable.component';


@NgModule({
  declarations: [
    BankkontoNotFoundComponent,
    BetriebNotFoundComponent,
    ServiceUnavailableComponent,
  ],
  exports: [
    BetriebNotFoundComponent,
  ],
  imports: [
    CommonModule,
    ErrorPagesRoutingModule,
    IconModule,
    MatButtonModule,
  ]
})
export class ErrorPagesModule {
}
