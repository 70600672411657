import {Component, OnDestroy} from '@angular/core';
import {IconService} from './services/icon.service';
import {UmsatzService} from './services/umsatz.service';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {IntentActionService} from './services/intent.action.service';
import {PortalMessageBroker} from './portal/portal-message-broker';


@Component({
  selector: 'ku-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {

  private readonly unsubscribe$ = new Subject<void>();

  runningInPortal$: Observable<boolean>;

  title = 'kontoumsaetze';

  constructor(
    private iconService: IconService,
    private umsatzService: UmsatzService,
    private intentActionService: IntentActionService,
    private portalMessageBroker: PortalMessageBroker,
    private oidcSecurityService: OidcSecurityService,
  ) {
    this.runningInPortal$ = this.portalMessageBroker.runningInPortal$
      .pipe(
        takeUntil(this.unsubscribe$),
      );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  doLogout(): void {
    this.oidcSecurityService.logoff();
  }
}
