<jf-app-skeleton [sidebar]="inhabersLoaded" [contentBorder]="true">
  <jf-betriebsauswahl
    betriebsauswahl
    [impressumLink]="'/impressum'"
    [badgeCounts]="badgeCounts"
    [tooltipText]="{
      singular: '${count} Kontoumsatz zu bearbeiten',
      plural: '${count} Kontoumsätze zu bearbeiten',
    }"
    (badgeClicked)="badgeClick($event)"
  ></jf-betriebsauswahl>

  <ng-container content>
    <router-outlet *ngIf="!showError"></router-outlet>
    <ku-betrieb-not-found *ngIf="showError"></ku-betrieb-not-found>
  </ng-container>
</jf-app-skeleton>

