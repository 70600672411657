import {jfLibReducers} from '@adnova/jf-ng-components';
import {ActionReducer, ActionReducerMap, MetaReducer} from '@ngrx/store';
import {AppState} from '../states/app.state';
import {environment} from '../../../environments/environment';


export const reducers: ActionReducerMap<AppState> = {
  ...jfLibReducers,
};

export function logger(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [logger] : [];
