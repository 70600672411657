import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {NGXLogger} from 'ngx-logger';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {SnackbarComponent} from '../modules/snackbar/snackbar.component';


@Injectable({
  providedIn: 'root'
})
export class ErrorpageService {

  constructor(
    private router: Router,
    private logger: NGXLogger,
    private snackBar: MatSnackBar,
  ) {
  }

  bankkontoNotFound(currentInhaberId: string): void {
    this.logger.error('bankkonto not found');

    this.router.navigate(['/error/bankaccount-not-found/inhaber/', currentInhaberId]);
  }

  betriebNotFound(): void {
    this.logger.error('betrieb not found');

    this.router.navigate(['/error/betrieb-not-found/']);
  }

  serviceUnavailable(): void {
    this.logger.error('koas-service unavailable');

    const errorUrl = '/error/service-unavailable';
    if (this.router.url !== errorUrl) {
      this.router.navigate([errorUrl]);
    }
  }

  belegServiceUnavailable(): void {
    this.logger.error('beleg-service unavailable');
    this.snackBar.openFromComponent(
      SnackbarComponent,
      {
        duration: 30000,
        panelClass: 'error',
        data: 'Belege können nicht geladen werden.\nBitte probiere es später erneut.'
      }
    );
  }
}
