import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {StatusData} from '../../../../interfaces/status-data.interface';
import {MatSelect} from '@angular/material/select';
import {StatusFilter} from '../../../../interfaces/status-filter.interface';
import {FormControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';


@Component({
  selector: 'ku-filter-status',
  templateUrl: './filter-status.component.html',
  styleUrls: ['./filter-status.component.scss']
})
export class FilterStatusComponent implements OnInit, OnDestroy {

  statusList: StatusData[] = [
    {
      text: 'Abgeschlossen',
      id: 'abgeschlossen',
    },
    {
      text: 'Nicht abgeschlossen',
      id: 'nichtAbgeschlossen',
    },
    {
      text: '',
      id: 'divider',
    },
    {
      text: 'Automatisch zugeordnet',
      id: 'automatischZugeordnet',
    },
    {
      text: 'Manuell zugeordnet',
      id: 'manuellZugeordnet',
    },
    {
      text: 'Nicht zugeordnet',
      id: 'nichtZugeordnet',
    },
    {
      text: '',
      id: 'divider',
    },
    {
      text: 'Ohne Beleg',
      id: 'ohneBeleg',
    },
  ];

  protected control = new FormControl<StatusFilter | undefined>(undefined);

  @ViewChild(MatSelect)
  private select?: MatSelect;

  @Input()
  set value(value: StatusFilter | undefined) {
    if (value === undefined) {
      this.control.setValue(undefined, {emitEvent: false});
    } else if (value !== this.control.value) {
      this.control.setValue(value, {emitEvent: false});
    }
  }

  @Output()
  public valueChange = new EventEmitter<StatusFilter>();

  protected closeOptionsTimeout!: any;

  private readonly unsubscribe$ = new Subject<void>();

  ngOnInit(): void {
    this.control.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe((value => {
      this.valueChange.emit(this.control.value || undefined);
    }));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  closeOptions(): void {
    this.closeOptionsTimeout = setTimeout(() => {
      this.select?.close();
    }, 500);
  }

  cancelCloseOptions(): void {
    clearTimeout(this.closeOptionsTimeout);
  }

}
