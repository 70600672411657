import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatExpansionPanel} from '@angular/material/expansion';
import {UmsatzFilterService} from './umsatz-filter.service';
import {BehaviorSubject, Subject, takeUntil} from 'rxjs';
import {BankkontoDTO, BankkontoService, PageableUmsatzDTOSortingEnum} from '../../openapi/kontoumsatz-openapi';
import {DateFilterData} from '../../interfaces/date-filter-data.interface';
import {StatusFilter} from '../../interfaces/status-filter.interface';
import {ActivatedRoute} from '@angular/router';
import {ChipInput, NavigationService} from '@adnova/jf-ng-components';
import * as moment from 'moment';
import {filter, take, timeout} from 'rxjs/operators';


export const DEFAULT_SORTING = PageableUmsatzDTOSortingEnum.Datumdesc;

type CurrentPage = 'to-assign' | 'to-clarify' | 'all-assigned';

@Component({
  selector: 'ku-umsatz-filter',
  templateUrl: './umsatz-filter.component.html',
  styleUrls: ['./umsatz-filter.component.scss']
})
export class UmsatzFilterComponent implements OnInit, OnDestroy {

  private readonly unsubscribe$ = new Subject<void>();

  @ViewChild(MatExpansionPanel)
  expansionPanel?: MatExpansionPanel;

  protected sorting = DEFAULT_SORTING;

  protected betragVon: number | undefined;

  protected betragBis: number | undefined;

  protected datumVonBis: DateFilterData | undefined;

  protected searchValue: string | undefined;

  protected bankValue: string[] | undefined;

  protected statusValue?: StatusFilter;

  protected bankkontoOptions$ = new BehaviorSubject<BankkontoDTO[]>([]);

  protected filterCount = 0;

  protected filterChips$ = new BehaviorSubject<ChipInput[]>([]);

  protected currentPage?: CurrentPage;

  constructor(
    protected umsatzFilterService: UmsatzFilterService,
    private bankkontoService: BankkontoService,
    private navigationService: NavigationService,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {

    this.currentPage = this.route.parent?.parent?.snapshot.routeConfig?.path as CurrentPage;

    this.navigationService.currentInhaber$.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(inhaber => {
      if (!inhaber) return;
      this.bankkontoService.readBankkonten(inhaber.id).subscribe(bankkonten => {
        this.bankkontoOptions$.next(bankkonten);
      });
    });

    this.umsatzFilterService.filterCountObservable$.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(filterCount => {
      this.filterCount = filterCount;
    });

    /*
     * INFO: Ändert sich der Expansion-State im Service,
     * wird das Expansion-Panel entsprechend geöffnet oder geschlossen.
     */
    this.umsatzFilterService.filterPanelExpandedObservable$.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(isExpanded => {
      if (this.expansionPanel?.expanded !== isExpanded) {
        this.expansionPanel?.toggle();
      }
    });

    this.umsatzFilterService.sortingObservable$.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(sorting => {
      this.sorting = sorting || DEFAULT_SORTING;
    });

    this.umsatzFilterService.filterBetragVonObservable$.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(betragVon => {
      this.betragVon = betragVon;

      this.manageFilterChip(
        this.betragVon,
        'betragVon',
        (value) =>
          'Betrag von ' + value.toFixed(2).replace('.', ',') + '€'
      );
    });

    this.umsatzFilterService.filterBetragBisObservable$.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(betragBis => {
      this.betragBis = betragBis;

      this.manageFilterChip(
        this.betragBis,
        'betragBis',
        (value) =>
          'Betrag bis ' + value.toFixed(2).replace('.', ',') + '€'
      );
    });

    this.umsatzFilterService.filterDatumVonObservable$.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe((from) => {
      this.datumVonBis = {
        ...this.datumVonBis,
        from,
      };

      this.manageFilterChip(
        this.datumVonBis?.from,
        'datumVon',
        (from) =>
          'Buchungsdatum von ' + moment(from).format('DD.MM.YYYY')
      );
    });

    this.umsatzFilterService.filterDatumBisObservable$.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe((to) => {
      this.datumVonBis = {
        ...this.datumVonBis,
        to,
      };

      this.manageFilterChip(
        this.datumVonBis?.to,
        'datumBis', (to) =>
          'Buchungsdatum bis ' + moment(to).format('DD.MM.YYYY')
      );
    });

    this.umsatzFilterService.filterSearchValueObservable$.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(searchValue => {
      this.searchValue = searchValue;

      this.manageFilterChip(
        this.searchValue,
        'search',
        (value) => value.replace(/ /g, ', ')
      );
    });

    this.umsatzFilterService.filterBankObservable$.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(bankValue => {
      this.bankValue = bankValue;

      this.bankkontoOptions$.pipe(
        filter(bankkonten => bankkonten.length > 0),
        timeout(30000), // INFO: Timeout nach 30 Sekunden, sollten bis dahin keine Bankkonten vorhanden sein.
        take(1),
      ).subscribe(bankkontoOptions => {
        const bankLabelFormatter = (bankId: string) => {
          const bank = bankkontoOptions.find(b => b.id === bankId);
          return bank?.bezeichnung || 'Unbekannte Bank';
        };
        this.manageFilterChip(bankValue, 'bank', bankLabelFormatter);
      });
    });

    this.umsatzFilterService.filterStatusObservable$.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(statusValue => {
      this.statusValue = statusValue;

      this.manageFilterChip(
        this.statusValue,
        'status',
        () => {
          switch (this.statusValue) {
            case 'abgeschlossen':
              return 'Abgeschlossen';
            case 'nichtAbgeschlossen':
              return 'Nicht abgeschlossen';
            case 'automatischZugeordnet':
              return 'Automatisch zugeordnet';
            case 'manuellZugeordnet':
              return 'Manuell zugeordnet';
            case 'nichtZugeordnet':
              return 'Nicht zugeordnet';
            case 'ohneBeleg':
              return 'Ohne Beleg';
            default:
              return 'Unbekannt';
          }
        },
      );
    });

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Verwaltet die Filter-Chips.
   * @param value Ist der Wert, der als Filter-Chip hinzugefügt oder entfernt werden soll.
   * Kann ein einzelner Wert, ein Array von Werten oder undefined sein.
   * Ist der Wert undefined, wird der Filter-Chip entfernt.
   * Ist der Wert ein Array, wird für jedes Element ein Filter-Chip hinzugefügt.
   * @param chipIdentifierBase Der Identifier für den Filter-Chip.
   * Ist value ein Array, wird für jedes Element ein eindeutiger Identifier erstellt.
   * Dieser ist eine Kombination aus chipIdentifierBase und dem Element, in folgendem Fotmat:
   * `${chipIdentifierBase}:${element}`.
   * @param labelFormatter Eine Funktion, die den Wert in einen lesbaren Label-String umwandelt.
   * @private
   */
  private manageFilterChip<T>(
    value: T | T[] | undefined,
    chipIdentifierBase: string,
    labelFormatter: (value: T) => string
  ): void {
    if (Array.isArray(value)) {
      // INFO: Wenn der Wert ein Array ist, verarbeite jedes Element
      const chips: ChipInput[] = value.map(element => {
        const chipIdentifier = `${chipIdentifierBase}:${element}`;
        return {
          id: chipIdentifier,
          label: labelFormatter(element),
        };
      });
      this.filterChips$.next([
        ...this.filterChips$.value.filter(chip => !chip.id.startsWith(chipIdentifierBase + ':')),
        ...chips,
      ]);
    } else {
      // INFO: Erstelle eine eindeutige ID für den Chip.
      const chipIdentifier = `${chipIdentifierBase}:${value}`;
      if (value !== undefined && value !== null) {
        // INFO: Wenn der Wert definiert ist, erstelle einen aktualisieren Value für filterChips$.
        this.filterChips$.next([
          ...this.filterChips$.value.filter(chip => !chip.id.startsWith(chipIdentifierBase + ':')),
          {
            id: chipIdentifier,
            label: labelFormatter(value),
          },
        ]);
      } else {
        // INFO: Wenn der Wert undefiniert ist, entferne den Chip aus filterChips$.
        this.filterChips$.next(
          this.filterChips$.value.filter(chip => !chip.id.startsWith(chipIdentifierBase + ':'))
        );
      }
    }
  }

  /*
   * INFO: Ändert sich der Expansion-State in der Component,
   * wird der State entsprechend im Service angepasst.
   */
  changeExpandState(isExpanded: boolean): void {
    if (isExpanded !== this.umsatzFilterService.filterPanelExpanded) {
      this.umsatzFilterService.filterPanelExpanded = isExpanded;
    }
  }

  changeSorting(sorting: PageableUmsatzDTOSortingEnum): void {
    if (sorting !== this.umsatzFilterService.sorting) {
      this.umsatzFilterService.sorting = sorting;
    }
  }

  changeBetragVon(betragVon: number | undefined): void {
    if (betragVon !== this.umsatzFilterService.filterBetragVon) {
      this.umsatzFilterService.filterBetragVon = betragVon;
    }
  }

  changeBetragBis(betragBis: number | undefined): void {
    if (betragBis !== this.umsatzFilterService.filterBetragBis) {
      this.umsatzFilterService.filterBetragBis = betragBis;
    }
  }

  changeDatum(datum: DateFilterData): void {
    if (datum.from !== this.umsatzFilterService.filterDatumVon) {
      this.umsatzFilterService.filterDatumVon = datum.from;
    }
    if (datum.to !== this.umsatzFilterService.filterDatumBis) {
      this.umsatzFilterService.filterDatumBis = datum.to;
    }
  }

  changeSearchValue(searchValue: string): void {
    if (searchValue !== this.umsatzFilterService.filterSearchValue) {
      this.umsatzFilterService.filterSearchValue = searchValue;
    }
  }

  changeBankState(bankValue: string[] | undefined): void {
    if (bankValue !== this.umsatzFilterService.filterBank) {
      this.umsatzFilterService.filterBank = bankValue;
    }
  }

  changeStatusState(statusValue: StatusFilter | undefined): void {
    if (statusValue !== this.umsatzFilterService.filterStatus) {
      this.umsatzFilterService.filterStatus = statusValue;
    }
  }

  resetAllFilter(): void {
    this.umsatzFilterService.resetFilter();
  }

  /**
   * Entfernt einen einzelnen Filter-Chip.
   * @param id Der Identifier des Filter-Chips.
   * Dieser wurde beim Hinzufügen des Filter-Chips mit der Methode {@link manageFilterChip} erstellt
   * und entspricht dem dort mitgegebenen `chipIdentifierBase`.
   * @protected
   */
  protected resetFilter(id?: string): void {
    if (id) {
      const removeId = id.split(':');
      switch (removeId[0]) {
        case 'betragVon':
          this.changeBetragVon(undefined);
          break;
        case 'betragBis':
          this.changeBetragBis(undefined);
          break;
        case 'datumVon':
          this.changeDatum({from: undefined, to: this.datumVonBis?.to});
          break;
        case 'datumBis':
          this.changeDatum({from: this.datumVonBis?.from, to: undefined});
          break;
        case 'search':
          this.changeSearchValue('');
          break;
        case 'bank':
          const newBankArray = this.bankValue?.filter(bank => bank !== removeId[1]);
          if (newBankArray?.length) {
            this.changeBankState([...newBankArray]);
          } else {
            this.changeBankState(undefined);
          }
          break;
        case 'status':
          this.changeStatusState(undefined);
          break;
        default:
          break;
      }
    }
  }
}
