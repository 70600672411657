import {Injectable, OnDestroy} from '@angular/core';
import {distinctUntilChanged, filter, map, takeUntil} from 'rxjs/operators';
import {interval, Subject} from 'rxjs';
import {BadgeValue, NavigationService, RouterSelectors} from '@adnova/jf-ng-components';
import {FilterUmsatzDTO, InhaberDTO, UmsatzService} from '../openapi/kontoumsatz-openapi';
import {UmsatzService as KuUmsatzService} from './umsatz.service';
import {ToAssignService} from '../modules/to-assign/to-assign.service';
import {ToClarifyService} from '../modules/to-clarify/to-clarify.service';
import {AllAssignedService} from '../modules/all-assigned/all-assigned.service';
import {AppState} from '../store/states/app.state';
import {Store} from '@ngrx/store';


@Injectable({
  providedIn: 'root'
})
export class BadgeValueService implements OnDestroy {
  private readonly _unsubscribe$ = new Subject<void>();
  private _badgeValues: BadgeValue[] = [];
  public badgeValues$ = new Subject<BadgeValue[]>();
  private _inhabers: InhaberDTO[] = [];

  constructor(
    private navigationService: NavigationService,
    private store: Store<AppState>,
    private umsatzService: UmsatzService,
    private toAssignService: ToAssignService,
    private toClarifyService: ToClarifyService,
    private allAssignedService: AllAssignedService,
    private kuUmsatzService: KuUmsatzService,
  ) {
    this.kuUmsatzService.finishedAssignment.pipe(
      takeUntil(this._unsubscribe$),
    ).subscribe(() => {
      this.updateBadgeValues();
    });

    /**
     * Wenn sich die Inhaber ändern sollten, werden die Badges neu abgefragt.
     * Das ist in der Regel aber nur initial der Fall, sodass die Badges beim
     * Laden der App einmalig abgefragt werden.
     */
    this.navigationService.inhaberList$.pipe(
      takeUntil(this._unsubscribe$),
      filter(inhaberId => inhaberId !== undefined),
      distinctUntilChanged(),
    ).subscribe(inhabers => {
      this._inhabers = inhabers;
      this.updateBadgeValues();
    });

    this.store.select(RouterSelectors.url).pipe(
      takeUntil(this._unsubscribe$),
    ).subscribe(url => {
      this.updateBadgeValues();
    });

    /**
     * Sobald ein Umsatz geupdated wird, wird der Badge-Wert des jeweiligen Inhabers neu abgefragt.
     */
    this.toAssignService.updatedUmsatz$.pipe(
      takeUntil(this._unsubscribe$),
    ).subscribe(umsatz => {
      this.updateBadgeValue(umsatz.inhaberId);
    });

    /**
     * Sobald ein Umsatz geupdated wird, wird der Badge-Wert des jeweiligen Inhabers neu abgefragt.
     */
    this.allAssignedService.updatedUmsatz$.pipe(
      takeUntil(this._unsubscribe$),
    ).subscribe(umsatz => {
      this.updateBadgeValue(umsatz.inhaberId);
    });

    /**
     * In einem Invervall von 60 Sekunden wird der Badge ebenfalls aktualisiert,
     * um auch nach Änderungen von außen eine korrekte Anzeige zu liefern.
     */
    interval(60_000).pipe(
      takeUntil(this._unsubscribe$),
    ).subscribe(() => {
      this.updateBadgeValues();
    });

  }


  /**
   * Es werden die Badges für jeden Inhaber abgefragt
   */
  public updateBadgeValues(): void {
    for (let inhaber of this._inhabers) {
      this.updateBadgeValue(inhaber.id);
    }
  }

  /**
   * Es wird der Badge eines bestimmten Inhabers neu abgefragt.
   * Dabei wird der Wert des Inhabers direkt im Array angepasst.
   * Fehlt ein Inhaber im Array, wird dieser hinzugefügt, sodass
   * beim ersten Aufruf der Funktion das Array initialisiert wird.
   *
   * @param inhaberId: ID des Inhabers
   */
  public updateBadgeValue(inhaberId: string): void {
    const filter: FilterUmsatzDTO = {
      abgeschlossen: false,
    }

    this.umsatzService.countUmsaetze(inhaberId, filter).pipe(
      map(value => value.count),
    ).subscribe(countValue => {
      const badge = this._badgeValues.filter(value => value.inhaberId === inhaberId)[0];
      if (badge) {
        this._badgeValues.filter(value => value.inhaberId === inhaberId)[0].countedBadgeValue = countValue;
      } else {
        this._badgeValues.push({inhaberId: inhaberId, countedBadgeValue: countValue});
      }
      /** INFO:
       * Der Wert wird als Kopie übergeben, damit der Setter in der Betriebsauswahlcomponent erkennt,
       * dass sich der Wert geändert hat. Dies tut er nicht, wenn nur der Value der Variable sich ändert.
       */
      this.badgeValues$.next([...this._badgeValues]);
    });
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }
}
