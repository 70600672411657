import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {FilterUmsatzDTO, UmsatzService} from '../../openapi/kontoumsatz-openapi';
import {filter, map, takeUntil} from 'rxjs/operators';
import {ToClarifyService} from '../to-clarify/to-clarify.service';
import {NavigationService} from '@adnova/jf-ng-components';


@Injectable({
  providedIn: 'root'
})
export class TabNavigationService implements OnDestroy {

  public countedToClarify$ = new BehaviorSubject<number>(0);

  private inhaberId = '';

  private readonly unsubscribe$ = new Subject<void>();

  public inhabersLoaded = this.navigationService.inhaberLoaded;

  constructor(
    private navigationService: NavigationService,
    private umsatzService: UmsatzService,
    private toClarifyService: ToClarifyService,
  ) {

    this.navigationService.inhaberLoaded$.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(inhabersLoaded => {
      this.inhabersLoaded = inhabersLoaded;
    });

    this.navigationService.currentInhaber$.pipe(
      map(inhaber => inhaber?.id),
      filter(id => id !== this.inhaberId),
      takeUntil(this.unsubscribe$),
    ).subscribe(id => {
      if (id) {
        this.inhaberId = id;

        this.countBadgeValue();
      }
    });

    /**
     * Sobald ein Umsatz geupdated wird, wird der Badge-Wert des jeweiligen Inhabers neu abgefragt.
     */
    this.toClarifyService.updatedUmsatz$.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(() => {
      this.countBadgeValue();
    });

    this.toClarifyService.umsaetze.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(() => {
      this.countBadgeValue();
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  countBadgeValue(): void {
    if (this.inhaberId) {
      const filter: FilterUmsatzDTO = {
        zurKlaerung: true,
      };

      this.umsatzService.countUmsaetze(this.inhaberId, filter).pipe(
        map(response => response.count),
        takeUntil(this.unsubscribe$),
      ).subscribe(counted => {
        this.countedToClarify$.next(counted);
      });
    }
  }
}
