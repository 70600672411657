import {NgModule} from "@angular/core";
import {TabNavigationComponent} from "./tab-navigation.component";
import {ToClarifyBadgeComponent} from "./to-clarify-badge/to-clarify-badge.component";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";

@NgModule({
  declarations: [
    TabNavigationComponent,
    ToClarifyBadgeComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
  ],
  exports: [
    TabNavigationComponent
  ]
})
export class TabNavigationModule {

}
