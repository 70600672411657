import {NgModule} from "@angular/core";
import {MatLegacyDialogModule as MatDialogModule} from "@angular/material/legacy-dialog";
import {AbrufErrorModalComponent} from "./abruf-error-modal.component";
import {MatLegacyButtonModule as MatButtonModule} from "@angular/material/legacy-button";
import {CommonModule} from "@angular/common";


@NgModule({
  declarations: [
    AbrufErrorModalComponent,
  ],
  exports: [
    AbrufErrorModalComponent,
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
  ]
})
export class AbrufErrorModalModule {
}
