import {Injectable} from '@angular/core';

import {Observable, of} from 'rxjs';
import {filter, map, take} from 'rxjs/operators';
import {UmsatzService} from '../services/umsatz.service';
import {NavigationService} from '@adnova/jf-ng-components';


@Injectable({
  providedIn: 'root'
})
export class FetchUmsaetzeWidgets  {

  constructor(
    private navigationService: NavigationService,
    private umsatzService: UmsatzService,
  ) {
  }

  resolve(): Observable<any> {
    this.navigationService.inhaberList$.pipe(
      filter(inhabers => !!inhabers),
      map(inhabers => inhabers!),
      take(1),
    ).subscribe(inhabers => {
      if (inhabers) {
        inhabers.forEach(inhaber => {
          this.umsatzService.getFetchedUmsaetze(inhaber.id, false);
        });
      }
    });
    return of(true);
  }
}
