import {NgModule} from '@angular/core';
import {BadgeComponent} from './badge.component';
import {CommonModule} from '@angular/common';
import {MatLegacyTooltipModule as MatTooltipModule} from "@angular/material/legacy-tooltip";

@NgModule({
  declarations: [
    BadgeComponent,
  ],
  imports: [
    CommonModule,
    MatTooltipModule
  ],
  exports: [
    BadgeComponent
  ]
})

export class BadgeModule {

}
