import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {BankkontoNotFoundComponent} from './bankkonto-not-found/bankkonto-not-found.component';
import {BetriebNotFoundComponent} from './betrieb-not-found/betrieb-not-found.component';
import {AppNavigationComponent} from '../pages/app-navigation/app-navigation.component';

const routes: Routes = [
  {
    path: 'bankaccount-not-found',
    component: AppNavigationComponent,
    children: [
      {
        path: 'inhaber/:inhaber',
        component: BankkontoNotFoundComponent,
        data: {
          redirectAllowed: true,
          useInhaberService: true,
        },
      },
      {
        path: '',
        component: BankkontoNotFoundComponent,
      },
    ]
  },
  {
    path: 'betrieb-not-found',
    component: AppNavigationComponent,
    children: [
      {
        path: '',
        component: BetriebNotFoundComponent,
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ErrorPagesRoutingModule {
}
