<mat-form-field appearance="outline">
  <mat-label>Status</mat-label>
  <mat-select [formControl]="control" disableOptionCentering="true">
    <div
      (mouseenter)="cancelCloseOptions()"
      (mouseleave)="closeOptions()"
    >
      <ng-container *ngFor="let status of statusList">
        <mat-option
          *ngIf="status.id !== 'divider'"
          [value]="status.id"
        >
          {{status.text}}
        </mat-option>
        <mat-divider *ngIf="status.id === 'divider'"></mat-divider>
      </ng-container>
    </div>
  </mat-select>
</mat-form-field>
