import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';


@Component({
  selector: 'ku-searchfield',
  templateUrl: './searchfield.component.html',
  styleUrls: ['./searchfield.component.scss']
})
export class SearchfieldComponent implements OnInit, OnDestroy {

  @ViewChild('input', {static: true})
  public input?: ElementRef;

  @Input()
  public value? = '';

  @Input()
  public debounceTime = 300;

  @Output()
  public valueChanged = new EventEmitter<string>();

  protected search$ = new Subject<string>();
  private readonly unsubscribe$ = new Subject<void>();

  ngOnInit(): void {
    this.search$.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(this.debounceTime),
    ).subscribe(() => {
      const inputValue = this.input?.nativeElement.value;
      this.valueChanged.emit(inputValue);
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  focus(): void {
    setTimeout(() => this.input?.nativeElement.focus(), 0);
  }
}
