import {Component} from '@angular/core';


@Component({
  selector: 'ku-betrieb-not-found',
  templateUrl: './betrieb-not-found.component.html',
  styleUrls: ['./betrieb-not-found.component.scss']
})
export class BetriebNotFoundComponent {

  constructor() {
  }
}
