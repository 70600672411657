import {Injectable, OnDestroy} from '@angular/core';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {NGXLogger} from 'ngx-logger';
import {UmsatzService} from '../../openapi/kontoumsatz-openapi';
import {UmsatzBaseService} from '../../services/umsatz-base.service';
import {NavigationService} from '@adnova/jf-ng-components';


@Injectable({
  providedIn: 'root'
})
export class ToClarifyService extends UmsatzBaseService implements OnDestroy {

  constructor(
    logger: NGXLogger,
    umsatzService: UmsatzService,
    navigationService: NavigationService,
    snackBar: MatSnackBar,
  ) {
    super(
      logger,
      umsatzService,
      navigationService,
      snackBar,
    );
  }

  ngOnDestroy() {
    this.unsubscribe();
  }
}
