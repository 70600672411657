<div class="umsatz-filter-wrapper">
  <div class="umsatz-filter-visible-list">
    <div>
      <ku-searchfield
        (valueChanged)="changeSearchValue($event)"
        [value]="searchValue"
      ></ku-searchfield>
      <ku-sorting
        (sortingChange)="changeSorting($event)"
        [(sorting)]="sorting"
      ></ku-sorting>
    </div>
    <button mat-flat-button class="umsatz-filter-button" (click)="expansionPanel?.toggle()">
      <ku-icon
        class="filter-icon"
        icon="common:filter"
        primaryStroke="#333333"
      ></ku-icon>
      {{ (umsatzFilterService.filterPanelExpanded) ? 'Filter verbergen' : 'Filter anzeigen' }}
      <ng-container *ngIf="filterCount">({{filterCount}})</ng-container>
    </button>
  </div>

  <div>
    <mat-accordion>
      <mat-expansion-panel
        (expandedChange)="changeExpandState($event)"
        [expanded]="umsatzFilterService.filterPanelExpanded"
      >
        <div class="sub-filter-row">
          <ku-filter-bank
            (valueChange)="changeBankState($event)"
            [(value)]="bankValue"
            [options]="(bankkontoOptions$ | async) || []"
          ></ku-filter-bank>
          <ku-filter-status
            *ngIf="currentPage === 'all-assigned'"
            (valueChange)="changeStatusState($event)"
            [(value)]="statusValue">
          </ku-filter-status>
          <ku-filter-datum
            (valueChange)="changeDatum($event)"
            [(value)]="datumVonBis"
          ></ku-filter-datum>
          <ku-filter-betrag-von-bis
            (valueVonChange)="changeBetragVon($event)"
            (valueBisChange)="changeBetragBis($event)"
            [(valueVon)]="betragVon"
            [(valueBis)]="betragBis"
          ></ku-filter-betrag-von-bis>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <jf-filter-chip-row
    [items]="(filterChips$ | async) || []"
    (onResetFilter)="resetFilter($event)"
    (onResetAllFilters)="resetAllFilter()"
  ></jf-filter-chip-row>
</div>
