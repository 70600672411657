import {Component, ElementRef, Input, OnInit} from '@angular/core';

@Component({
  selector: 'ku-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})

/**
 * DESCRIPTION
 * ===========
 * Use dynamic colors for the icons. You can use up to two colors per definition: Primary and accent. You can use the
 * theme denotations "primary", "accent" and "warn" or any CSS color code and color name. Theme denotations are
 * preferred over css colors.
 *
 * PREPARATION
 * ===========
 * To use dynamic colors you have to remove all "fill" and "stroke" attributes from the .svg-file. To use accent colors
 * set additionally "accent"-classes to all accent elements, for example:
 * ```
 * <circle cx="12" cy="12" r="9" class="accent" />
 * ```
 */

export class IconComponent implements OnInit {

  /**
   * Namespace and Iconname. Use this format "namespace:iconname"
   */
  @Input()
  icon!: string;

  /**
   * Primary color for strokes.
   */
  @Input()
  primaryStroke?: string = 'primary';

  /**
   * Accent color for strokes. If not set, the primary color is also used for accent elements.
   */
  @Input()
  accentStroke?: string;

  /**
   * Primary color to fill elements.
   */
  @Input()
  primaryFill?: string = 'none';

  /**
   * Accent color to fill elements. If not set, the primary color is also used for accent elements.
   */
  @Input()
  accentFill?: string;

  /**
   * These are some variables to pass data to the template- and .scss-files.
   */
  public primaryStrokeClass: string = '';
  public accentStrokeClass: string = '';
  public primaryFillClass: string = '';
  public accentFillClass: string = '';

  public primaryStrokeStyle: string = '';
  public accentStrokeStyle: string = '';
  public primaryFillStyle: string = '';
  public accentFillStyle: string = '';

  constructor(
    private elementRef: ElementRef
  ) { }

  ngOnInit(): void {

    /**
     * If primary stroke color is set, it will check if it is a theme denotation. In this case it will add a class, that
     * the theme can set the regarding color. Otherwise the css color is saved to be injected into a css variable.
     */
    if (this.primaryStroke) {
      switch (this.primaryStroke) {
        case 'primary':
        case 'accent':
        case 'warn':
          this.primaryStrokeClass = this.primaryStroke + '-primaryStroke';
          break;
        default:
          this.primaryStrokeStyle = this.primaryStroke;
      }
    }

    /**
     * If accent stroke color is set, it will check if it is a theme denotation. In this case it will add a class, that
     * the theme can set the regarding color. Otherwise the css color is saved to be injected into a css variable.
     */
    if (this.accentStroke) {
      switch (this.accentStroke) {
        case 'primary':
        case 'accent':
        case 'warn':
          this.accentStrokeClass = this.accentStroke + '-accentStroke';
          break;
        default:
          this.accentStrokeStyle = this.accentStroke;
      }
    }

    /**
     * If primary fill color is set, it will check if it is a theme denotation. In this case it will add a class, that
     * the theme can set the regarding color. Otherwise the css color is saved to be injected into a css variable.
     */
    if (this.primaryFill) {
      switch (this.primaryFill) {
        case 'primary':
        case 'accent':
        case 'warn':
          this.primaryFillClass = this.primaryFill + '-primaryFill';
          break;
        default:
          this.primaryFillStyle = this.primaryFill;
      }
    }

    /**
     * If accent fill color is set, it will check if it is a theme denotation. In this case it will add a class, that
     * the theme can set the regarding color. Otherwise the css color is saved to be injected into a css variable.
     */
    if (this.accentFill) {
      switch (this.accentFill) {
        case 'primary':
        case 'accent':
        case 'warn':
          this.accentFillClass = this.accentFill + '-accentFill';
          break;
        default:
          this.accentFillStyle = this.accentFill;
      }
    }

    /**
     * the prepared css colors will be injected into css variables that can be used in the .scss-files.
     */
    this.elementRef.nativeElement.style.setProperty('--primary-stroke-style', this.primaryStrokeStyle);
    this.elementRef.nativeElement.style.setProperty('--accent-stroke-style', this.accentStrokeStyle);
    this.elementRef.nativeElement.style.setProperty('--primary-fill-style', this.primaryFillStyle);
    this.elementRef.nativeElement.style.setProperty('--accent-fill-style', this.accentFillStyle);

  }
}


@Component({
  selector: 'ku-icon',
  template: '<span></span>'
})
export class IconTestingComponent {
  @Input()
  icon: any;

  @Input()
  primaryStroke: any;

  @Input()
  accentStroke: any;

  @Input()
  primaryFill: any;

  @Input()
  accentFill: any;
}
