import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, combineLatest, debounceTime, filter, map, Observable, Subject, take, takeUntil} from 'rxjs';
import {ActivatedRoute, ParamMap, Params, Router} from '@angular/router';
import {BankkontoDTO, PageableUmsatzDTOSortingEnum} from '../../openapi/kontoumsatz-openapi';
import {StatusFilter} from '../../interfaces/status-filter.interface';


type ParamTypes = number | Date | string | string[] | boolean | PageableUmsatzDTOSortingEnum | undefined;

/** Enum um die Suchfelder zu identifizieren */
export type SearchAttribute =
  'suche'
  | 'bank'
  | 'status'
  | 'datumVon'
  | 'datumBis'
  | 'betragVon'
  | 'betragBis'
  | 'umsaetze'
  | 'umsaetzeTitle';

/** Datentyp für den Filter */
export interface FilterData {
  searchAttribute?: SearchAttribute,
  value?: string,
  bankkontoId?: string,
  labelTitle?: string,
}

/** Initiale Filter-Daten für das Popup */
export interface InitialFilterData {
  filterDatas: FilterData[],
  bankkonten: BankkontoDTO[],
}

@Injectable({
  providedIn: 'root'
})
export class UmsatzFilterService implements OnDestroy {

  private readonly unsubscribe$ = new Subject<void>();

  private readonly filterPanelExpanded$ = new BehaviorSubject(false);

  private readonly sorting$ = new BehaviorSubject<PageableUmsatzDTOSortingEnum | undefined>(undefined);

  private readonly filterBetragVon$ = new BehaviorSubject<number | undefined>(undefined);

  private readonly filterBetragBis$ = new BehaviorSubject<number | undefined>(undefined);

  private readonly filterDatumVon$ = new BehaviorSubject<string | undefined>(undefined);

  private readonly filterDatumBis$ = new BehaviorSubject<string | undefined>(undefined);

  private readonly filterSearchValue$ = new BehaviorSubject<string | undefined>(undefined);

  private readonly filterBank$ = new BehaviorSubject<string[] | undefined>(undefined);

  private readonly filterStatus$ = new BehaviorSubject<StatusFilter | undefined>(undefined);

  public pathQueries = new Map<string, Params>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {
    /*
     * INFO: Aktuelle Query-Params für den Pfad zwischenspeichern,
     * damit die Links in der Tab-Navigation auf die korrekten Filter zeigen.
     */
    this.route.queryParams.subscribe(params => {
      this.route.firstChild?.url.pipe(
        take(1),
        map(url => url.at(0)?.path),
        filter((path): path is string => !!path),
      ).subscribe(path => {
        this.pathQueries.set(path, params);
      });
    });

    // INFO: Setzen der Werte aus den URL-Query-Parametern in die Formularfelder für die Filter.
    this.route.queryParamMap.subscribe(paramMap => {
      this.setSubjectFromQueryParam(paramMap, 'sorting', this.sorting$, 'sorting');
      this.setSubjectFromQueryParam(paramMap, 'betragVon', this.filterBetragVon$, 'number');
      this.setSubjectFromQueryParam(paramMap, 'betragBis', this.filterBetragBis$, 'number');
      this.setSubjectFromQueryParam(paramMap, 'datumVon', this.filterDatumVon$, 'string');
      this.setSubjectFromQueryParam(paramMap, 'datumBis', this.filterDatumBis$, 'string');
      this.setSubjectFromQueryParam(paramMap, 'suche', this.filterSearchValue$, 'string');
      this.setSubjectFromQueryParam(paramMap, 'bank', this.filterBank$, 'stringArray');
      this.setSubjectFromQueryParam(paramMap, 'status', this.filterStatus$, 'string');
    });

    // INFO: Setzen der Werte aus den Formularfeldern für die Filter in die URL-Query-Parameter.
    this.subscribeToUpdateQueryParam(this.sorting$, 'sorting');
    this.subscribeToUpdateQueryParam(this.filterBetragVon$, 'betragVon');
    this.subscribeToUpdateQueryParam(this.filterBetragBis$, 'betragBis');
    this.subscribeToUpdateQueryParam(this.filterDatumVon$, 'datumVon');
    this.subscribeToUpdateQueryParam(this.filterDatumBis$, 'datumBis');
    this.subscribeToUpdateQueryParam(this.filterSearchValue$, 'suche');
    this.subscribeToUpdateQueryParam(this.filterBank$, 'bank');
    this.subscribeToUpdateQueryParam(this.filterStatus$, 'status');
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Liest den spezifizierten Query-Parameterwert aus der URL und konvertiert ihn in den angegebenen Typ.
   * Der konvertierte Wert wird dann genutzt, um das übergebene BehaviorSubject zu aktualisieren,
   * sofern sich der Wert geändert hat.
   *
   * Generell werden die Typen 'number', 'boolean', 'string' und 'date' unterstützt.
   * Für 'date' erwartet die Funktion das Format 'YYYY-MM-DD'.
   *
   * Die Funktion gibt eine Warnmeldung in der Konsole aus, wenn ein nicht unterstützter Typ angegeben wird.
   *
   * @param {ParamMap} paramMap - Das Objekt mit den Query-Parametern einer Route.
   * @param {string} paramName - Der Name des Query-Parameters, der gelesen werden soll.
   * @param {BehaviorSubject<any>} subject - Das BehaviorSubject, welches aktualisiert werden soll.
   * @param {'number' | 'boolean' | 'string' | 'date'} T - Der Typ, in den der Query-Parameter konvertiert werden soll.
   *                     Gültige Werte sind 'number', 'boolean', 'string' und 'date'.
   */
  private setSubjectFromQueryParam(
    paramMap: ParamMap,
    paramName: string,
    subject: BehaviorSubject<any>,
    T: 'number' | 'boolean' | 'string' | 'stringArray' | 'date' | 'sorting'
  ): void {
    // INFO: Abrufen des Werts des Query-Parameters, bzw. setzen auf "null", wenn kein Wert vorhanden ist.
    const paramValue = paramMap.get(paramName) || null;

    // INFO: Initialisierung der Variablen für den konvertierten Wert
    let subjectValue: ParamTypes = undefined;

    // INFO: Überprüfung, ob der Parameter existiert und Konvertierung in den entsprechenden Typ
    if (paramValue !== null) {
      switch (T) {
        case 'number':
          subjectValue = +paramValue;
          break;
        case 'boolean':
          subjectValue = paramValue.toLowerCase() === 'true';
          break;
        case 'string':
          subjectValue = paramValue; // Keine Umwandlung nötig
          break;
        case 'stringArray':
          subjectValue = paramValue.split(',');
          break;
        case 'sorting':
          subjectValue = paramValue; // Keine Umwandlung nötig
          break;
        case 'date':
          const dateMatch = /^(\d{4}-\d{2}-\d{2})$/.exec(paramValue);
          if (dateMatch) {
            subjectValue = new Date(dateMatch[1]);
          }
          break;
        default:
          console.warn(`Type "${T}" is not supported by setSubjectFromQueryParam.`);
      }
    }

    // INFO: Aktualisierung des BehaviorSubjects, wenn sich der Wert geändert hat
    if (JSON.stringify(subject.value) !== JSON.stringify(subjectValue)) {
      subject.next(subjectValue);
    }
  }

  /**
   * Aktualisiert den spezifizierten Query-Parameter in der URL.
   * Wenn der neue Wert `undefined` ist, wird der Query-Parameter entfernt.
   * Wenn der Wert sich von dem aktuellen Wert unterscheidet, wird die Navigation
   * mit neuen Query-Parametern ausgeführt.
   *
   * @param {string} paramName - Der Name des Query-Parameters, der aktualisiert werden soll.
   * @param {T} paramValue - Der neue Wert des Query-Parameters, kann `undefined` sein.
   */
  private updateQueryParam<T extends ParamTypes>(
    paramName: string,
    paramValue: T
  ): void {
    // INFO: Erhalte das aktuelle QueryParamMap als Observable.
    this.route.queryParamMap.pipe(
      // INFO: Nimm nur die erste Emission, um zu vermeiden, dass wir kontinuierlich abonnieren.
      take(1),
    ).subscribe(paramMap => {
      // INFO: Transformiere den Eingabewert in einen String, `null` wenn `undefined`.
      const queryStringValue
        = paramValue === undefined || paramValue === null
        ? null
        : String(paramValue);

      // INFO: Vergleiche den aktuellen Parameterwert mit dem neuen Wert.
      if (paramMap.get(paramName) !== queryStringValue) {
        // INFO: Navigiere nur, wenn der Wert geändert wurde, um unnötige Navigationsereignisse zu vermeiden.
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: {[paramName]: queryStringValue},
          queryParamsHandling: 'merge', // INFO: Füge die neuen Query-Parameter zu den bestehenden hinzu.
        });
      }
    });
  }

  private subscribeToUpdateQueryParam(subject: BehaviorSubject<any>, paramName: string): void {
    subject.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(value => {
      this.updateQueryParam(paramName, value);
    });
  }

  resetFilter(): void {
    this.route.queryParamMap.pipe(
      take(1),
    ).subscribe(paramMap => {
      // INFO: Sortierung soll beim Zurücksetzen der Filter beibehalten werden.
      const sorting = paramMap.get('sorting') || undefined;
      const queryParams = {sorting};

      /*
       * INFO: Zurücksetzen der Filter über QueryParams und nicht über BehaviourSubjects,
       * da hier der gesamte State auf einmal zurückgesetzt werden kann,
       * anstatt pro Filter einmal ein BehaviourSubject zu triggern.
       * Das führt nämlich zu ungewünschten Effekten: Pro Klick auf den Reset-Button wird nur
       * einer der Filter zurückgesetzt.
       */
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams,
      });
    });
  }

  get filterPanelExpandedObservable$(): Observable<boolean> {
    return this.filterPanelExpanded$.asObservable();
  }

  get filterPanelExpanded(): boolean {
    return this.filterPanelExpanded$.value;
  }

  set filterPanelExpanded(value: boolean) {
    this.filterPanelExpanded$.next(value);
  }

  get sortingObservable$(): Observable<PageableUmsatzDTOSortingEnum | undefined> {
    return this.sorting$.asObservable();
  }

  get sorting(): PageableUmsatzDTOSortingEnum | undefined {
    return this.sorting$.value;
  }

  set sorting(value: PageableUmsatzDTOSortingEnum | undefined) {
    this.sorting$.next(value);
  }

  get filterBetragVonObservable$(): Observable<number | undefined> {
    return this.filterBetragVon$.asObservable();
  }

  get filterBetragVon(): number | undefined {
    return this.filterBetragVon$.value;
  }

  set filterBetragVon(value: number | undefined) {
    this.filterBetragVon$.next(value);
  }

  get filterBetragBisObservable$(): Observable<number | undefined> {
    return this.filterBetragBis$.asObservable();
  }

  get filterBetragBis(): number | undefined {
    return this.filterBetragBis$.value;
  }

  set filterBetragBis(value: number | undefined) {
    this.filterBetragBis$.next(value);
  }

  get filterDatumVonObservable$(): Observable<string | undefined> {
    return this.filterDatumVon$.asObservable();
  }

  get filterDatumVon(): string | undefined {
    return this.filterDatumVon$.value;
  }

  set filterDatumVon(value: string | undefined) {
    this.filterDatumVon$.next(value);
  }

  get filterDatumBisObservable$(): Observable<string | undefined> {
    return this.filterDatumBis$.asObservable();
  }

  get filterDatumBis(): string | undefined {
    return this.filterDatumBis$.value;
  }

  set filterDatumBis(value: string | undefined) {
    this.filterDatumBis$.next(value);
  }

  get filterSearchValueObservable$(): Observable<string | undefined> {
    return this.filterSearchValue$.asObservable();
  }

  get filterSearchValue(): string | undefined {
    return this.filterSearchValue$.value;
  }

  set filterSearchValue(value: string | undefined) {
    this.filterSearchValue$.next(value);
  }

  get filterBankObservable$(): Observable<string[] | undefined> {
    return this.filterBank$.asObservable();
  }

  get filterBank(): string[] | undefined {
    return this.filterBank$.value;
  }

  set filterBank(value: string[] | undefined) {
    this.filterBank$.next(value);
  }

  get filterStatusObservable$(): Observable<StatusFilter | undefined> {
    return this.filterStatus$.asObservable();
  }

  get filterStatus(): StatusFilter | undefined {
    return this.filterStatus$.value;
  }

  set filterStatus(value: StatusFilter | undefined) {
    this.filterStatus$.next(value);
  }

  get filterCountObservable$(): Observable<number> {
    return combineLatest([
      this.filterBetragVon$,
      this.filterBetragBis$,
      this.filterDatumVon$,
      this.filterDatumBis$,
      this.filterSearchValue$,
      this.filterBank$,
      this.filterStatus$,
    ]).pipe(
      map(
        filters => filters.filter(
          value => !!value
        ).length
      )
    );
  }

}
