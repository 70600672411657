import { Injectable } from '@angular/core';
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";

export type IconNamespace = 'common' | 'card' | 'pdf-viewer';
export interface IconSpec {
  namespace: IconNamespace,
  name: string
}

@Injectable({
  providedIn: 'root'
})
export class IconService {

  iconList: IconSpec[] = [
    {
      namespace: 'common',
      name: 'check_ring_light',
    },

    {
      namespace: 'common',
      name: 'dell_light',
    },
    {
      namespace: 'common',
      name: 'eye',
    },
    {
      namespace: 'common',
      name: 'link',
    },
    {
      namespace: 'common',
      name: 'time',
    },
    {
      namespace: 'common',
      name: 'done',
    },
    {
      namespace: 'common',
      name: 'plus',
    },
    {
      namespace: 'common',
      name: 'file',
    },
    {
      namespace: 'common',
      name: 'close',
    },
    {
      namespace: 'common',
      name: 'info',
    },
    {
      namespace: 'common',
      name: 'circumflex_up',
    },
    {
      namespace: 'common',
      name: 'circumflex_down',
    },
    {
      namespace: 'common',
      name: 'error_circle',
    },
    {
      namespace: 'common',
      name: 'gear',
    },
    {
      namespace: 'common',
      name: 'comment',
    },
    {
      namespace: 'common',
      name: 'arrow-up',
    },
    {
      namespace: 'common',
      name: 'arrow-down',
    },
    {
      namespace: 'common',
      name: 'arrow-right',
    },
    {
      namespace: 'common',
      name: 'arrow-right-2',
    },
    {
      namespace: 'common',
      name: 'arrow-left',
    },
    {
      namespace: 'common',
      name: 'assigned-automatically',
    },
    {
      namespace: 'common',
      name: 'assigned-manually',
    },
    {
      namespace: 'card',
      name: 'pattern',
    },
    {
      namespace: 'pdf-viewer',
      name: 'search',
    },
    {
      namespace: 'pdf-viewer',
      name: 'print',
    },
    {
      namespace: 'pdf-viewer',
      name: 'download',
    },
    {
      namespace: 'common',
      name: 'beleg',
    },
    {
      namespace: 'common',
      name: 'dot',
    },
    {
      namespace: 'common',
      name: 'burger-menu',
    },
    {
      namespace: 'common',
      name: 'burger-menu-dot',
    },
    {
      namespace: 'common',
      name: 'arrow-transfer',
    },
    {
      namespace: 'common',
      name: 'empty-state-widget',
    },
    {
      namespace: 'common',
      name: 'no-bank-account-widget',
    },
    {
      namespace: 'common',
      name: 'access_error',
    },
    {
      namespace: 'common',
      name: 'search',
    },
    {
      namespace: 'common',
      name: 'filter',
    },
  ]

  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
  ) {
    this.iconList.forEach(icon => {
      iconRegistry.addSvgIconInNamespace(
        icon.namespace,
        icon.name,
        sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/${icon.namespace}/${icon.name}.svg`));
    })
  }
}
