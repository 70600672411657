import {Injectable, OnDestroy} from '@angular/core';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {catchError, distinctUntilChanged, filter, map, switchMap, takeUntil} from 'rxjs/operators';
import {BankkontoDTO, BankkontoService as KontoService, InhaberDTO} from '../openapi/kontoumsatz-openapi';
import {ErrorpageService} from './errorpage.service';
import {NavigationService} from '@adnova/jf-ng-components';


@Injectable({
  providedIn: 'root'
})
export class BankkontoService implements OnDestroy {

  private readonly unsubscribe$ = new Subject<void>();

  private bankkonten$ = new BehaviorSubject<BankkontoDTO[]>([]);

  constructor(
    private navigationService: NavigationService,
    private kontoService: KontoService,
    private snackBar: MatSnackBar,
    private errorPageService: ErrorpageService,
  ) {
    this.navigationService.currentInhaber$.pipe(
      takeUntil(this.unsubscribe$),
      filter((inhaber): inhaber is InhaberDTO => !!inhaber),
      map(inhaber => inhaber.id),
      distinctUntilChanged(),
      switchMap(inhaberId => this.kontoService.readBankkonten(inhaberId as string, {}, {})),
      catchError(error => {
        this.snackBar.open(
          'Bankkonten konnten nicht geladen werden',
          undefined,
          {duration: 5000, panelClass: 'error'}
        );

        const emptyArray: BankkontoDTO[] = [];
        return of(emptyArray);
      }),
    ).subscribe(value => {
      this.bankkonten$.next(value);

      if (value.length === 0) {
        this.errorPageService.bankkontoNotFound(this.navigationService.currentInhaber?.id || '');
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Gibt die Bankkonten zurück
   */
  get bankkonten(): Observable<BankkontoDTO[]> {
    return this.bankkonten$.asObservable();
  }

  getBankkontoById(id: string | null): BankkontoDTO | undefined {
    const filtered = this.bankkonten$.getValue().filter(bankkonto => bankkonto.id === id);
    if (filtered.length === 1) {
      return filtered[0];
    }
    return undefined;
  }

  fetchBankkonten(): void {
    this.kontoService.readBankkonten(
      (this.navigationService.currentInhaber?.id || ''), {}, {}).subscribe(bankkonten => {

      this.bankkonten$.next(bankkonten);

      if (bankkonten.length === 0) {
        this.errorPageService.bankkontoNotFound(this.navigationService.currentInhaber?.id || '');
      }
    }, error => {
      this.snackBar.open(
        'Bankkonten konnten nicht geladen werden',
        undefined,
        {duration: 5000, panelClass: 'error'}
      );
    });
  }
}
