/**
 * Kontoumsatzassistent
 * Die Kontoumsätze eines Bankkontos gelangen von der jeweiligen Bank der Anwender über die Datendrehscheibe in eine Datenbank und können von dort abgefragt werden. Den Kontoumsätzen können automatisch Belege zugeordnet werden. Gibt es keinen Umsatz der genau passt, werden dem Anwender Vorschläge gemacht aus denen manuell ausgewählt werden kann. Die Funktionen des Moduls Kontoumsatzassistent in ADNOVA+ werden mit Hilfe des KoAs-Services für das Portal zur Verfügung gestellt. 
 *
 * The version of the OpenAPI document: 1.1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Gibt die Position eines Belegs mit einer Zahlung im Bearbeitungs-Prozess an
 */
export enum BelegRechnungsworkflowDTO {
    InErfassung = 'InErfassung',
    Ueberweisen = 'Ueberweisen',
    Abgeschlossen = 'Abgeschlossen'
};

