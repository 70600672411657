import {Component, Input} from '@angular/core';

@Component({
  selector: 'ku-to-clarify-badge',
  templateUrl: './to-clarify-badge.component.html',
  styleUrls: ['./to-clarify-badge.component.scss']
})
export class ToClarifyBadgeComponent {

  @Input()
  count?: number;

  constructor() {
  }
}
