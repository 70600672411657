<h3 class="title">Fehlende Zugriffsrechte</h3>
<div class="info">
  Du hast keine Zugriffsrechte für diese Anwendung oder diesen Betrieb. Deshalb <br>
  werden dir hier keine Inhalte angezeigt. Bitte wende dich an deinen <br>
  Administrator oder Betriebsleiter.
</div>
<ku-icon
  icon="common:access_error"
  primaryStroke="none"
  class="access-error-icon"
></ku-icon>
