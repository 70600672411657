<div class="title">Fehler bei dem Aktualisieren deiner Kontoumsätze</div>
<div class="message">{{data.message}}</div>
<div class="standard-text">Bitte versuche es zu einem späteren Zeitpunkt erneut.</div>
<div class="email-wrapper">
  <a href="mailto:service@just-farming.de" class="email">Just Farming Benutzerservice kontaktieren</a>
</div>
<button
  class="assign-btn"
  mat-raised-button
  color="accent"
  [mat-dialog-close]="true"
  cdkFocusInitial
>
  Schließen
</button>
