import {
  BelegVorschlagDTO,
  FilterUmsatzDTO,
  InhaberDTO,
  PageableUmsatzDTO,
  PageableUmsatzDTOSortingEnum,
  UmsatzDTO,
  UmsatzService,
  UpdateUmsatzRequestDTO
} from '../openapi/kontoumsatz-openapi';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {NGXLogger} from 'ngx-logger';
import {BehaviorSubject, from, Observable, of, Subject, Subscription} from 'rxjs';
import {
  catchError,
  debounceTime,
  delay,
  filter,
  finalize,
  map,
  mergeMap,
  switchMap,
  takeUntil,
  tap
} from 'rxjs/operators';
import {DEFAULT_SORTING} from '../modules/umsatz-filter/umsatz-filter.component';
import {NavigationService} from '@adnova/jf-ng-components';
import {UmsatzFilter} from '../interfaces/umsatz-filter.interface';
import {Pageable} from '../interfaces/pageable.interface';


export class UmsatzBaseService {

  protected readonly unsubscribe$ = new Subject<void>();

  private inhabers$ = new BehaviorSubject<InhaberDTO[]>([]);

  private umsaetzeCount$ = new BehaviorSubject<number>(-1);

  private umsaetze$ = new BehaviorSubject<UmsatzDTO[]>([]);

  private nextUmsaetze$ = new BehaviorSubject<UmsatzDTO[]>([]);

  public updatedUmsatz$ = new Subject<UmsatzDTO>();

  public sorting$!: BehaviorSubject<PageableUmsatzDTOSortingEnum>;

  private filter$: BehaviorSubject<UmsatzFilter>;

  constructor(
    private logger: NGXLogger,
    protected umsatzService: UmsatzService,
    protected navigationService: NavigationService,
    protected snackBar: MatSnackBar,
  ) {
    this.initSorting();

    this.filter$ = new BehaviorSubject<UmsatzFilter>({
      inhaberId: '',
      filter: {},
      pageable: {
        pageSize: 50,
        pageIndex: 0,
      },
      sorting: this.sorting$.value,
    });

    this.navigationService.inhaberList$.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(inhabers => {
      this.inhabers$.next(inhabers);
    });

    this.navigationService.currentInhaber$.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(value => {
      if (value) {
        this.updateFilterInhaber(value.id);
      }
    });

    this.filter$.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(400),
      tap(filter => {
        this.sorting$.next(filter.sorting);
      }),
      switchMap(filter => {
        return this.readUmsaetze(filter);
      })
    ).subscribe(value => {
      this.umsaetze$.next(value);
    });

    this.filter$.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(400),
      switchMap(filter => this.countUmsaetze(filter))
    ).subscribe(value => {
      this.umsaetzeCount$.next(value);
    });
  }

  /**
   * Initial die Sortierung setzen.
   */
  public initSorting(): void {
    const sorting = DEFAULT_SORTING;
    this.sorting$ = new BehaviorSubject<PageableUmsatzDTOSortingEnum>(sorting);
  }

  /**
   * Für folgende Aufrufe die Sortierung aktualisieren.
   */
  public reInitSorting(): void {
    const sorting = DEFAULT_SORTING;
    this.sorting$.next(sorting);
    this.filter$.next({...this.filter$.value, sorting});
  }

  public resetUmsaetze(): void {
    this.umsaetze$.next([]);
    this.umsaetzeCount$.next(-1);
  }

  protected unsubscribe(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Triggert das Zählen der Umsätze
   *
   * @param filter Filterkriterien der Umsätze
   */
  protected countUmsaetze(
    filter: UmsatzFilter,
  ): Observable<number> {
    if (filter.inhaberId === '') {
      return of(0);
    }

    return this.umsatzService.countUmsaetze(filter.inhaberId, filter.filter)
      .pipe(
        catchError(error => {
          return of(undefined);
        }),
        map(value => {
          if (value) {
            return value.count;
          }
          return 0;
        }),
      );
  }

  /**
   * Triggert das Abrufen der Umsätze entsprechend dem Filter
   *
   * @param filter Filterkriterien der Umsätze
   */
  private readUmsaetze(
    filter: UmsatzFilter,
    offset?: number,
    limit?: number,
  ): Observable<UmsatzDTO[]> {
    if (filter.inhaberId === '') {
      return of([]);
    }

    const umsatzPageable = filter.pageable;
    const pageable: PageableUmsatzDTO = {};

    if (offset && limit) {
      pageable.offset = offset;
      pageable.limit = limit;
    } else {
      pageable.offset = umsatzPageable.pageSize * umsatzPageable.pageIndex;
      pageable.limit = umsatzPageable.pageSize;
    }

    if (filter.sorting) {
      pageable.sorting = [filter.sorting];
    } else {
      pageable.sorting = [PageableUmsatzDTOSortingEnum.Datumdesc];
    }

    return this.umsatzService.readUmsaetze(filter.inhaberId, filter.filter, pageable)
      .pipe(
        takeUntil(this.unsubscribe$),
        catchError(error => {
          this.snackBar.open(
            'Umsätze konnten nicht geladen werden',
            undefined,
            {duration: 5000, panelClass: 'error'}
          );

          const emptyArray: UmsatzDTO[] = [];
          return of(emptyArray);
        }),
      );
  }

  /**
   * Lädt den ersten Umsatz der nächsten Seite.
   */
  readNextUmsatz(): void {
    let filter = this.filter$.value;
    this.readUmsaetze(filter, filter.pageable.pageSize - 1, 1).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(umsaetze => {
      this.nextUmsaetze$.next(umsaetze);
    });
  }

  /**
   * Laden der Vorschläge zu einem Kontoumsatz
   *
   * @param inhaberId Id die einen Inhaber identifiziert.&lt;br&gt;
   * @param umsatzId Id die einen Umsatz identifiziert.&lt;br&gt;
   */
  getVorschlaege(
    inhaberId: string,
    umsatzId: string,
  ): Observable<BelegVorschlagDTO[]> {
    return this.umsatzService.getVorschlaege(
      inhaberId,
      umsatzId,
    ).pipe(
      takeUntil(this.unsubscribe$),
      // INFO: Stornierte Belege herausfiltern.
      map((vorschlaege => vorschlaege.filter(vorschlag => !vorschlag.beleg.storniert))),
      delay(1000),
      catchError(err => {
        this.logger.warn('could not load vorschlage', err);
        return of([]);
      }),
    );
  }

  saveAllAssigned(
    umsatze: ({
      umsatz: UmsatzDTO,
      onSuccess?: () => void,
      onError?: () => void,
    })[],
    onComplete?: () => void,
  ): void {
    if (umsatze.length > 0) {
      let counter = 0;
      from(umsatze)
        .pipe(
          mergeMap(umsatz => {
            return this.updateUmsatz(
              umsatz.umsatz,
              true,
              umsatz.onSuccess,
              umsatz.onError,
            );
          }),
          tap(() => {
            counter++;
            if (onComplete && counter === umsatze.length) {
              onComplete();
            }
          }),
        ).subscribe();
    }
  }

  /**
   * Triggert das Updaten eines Umsatzes
   *
   * @param updateUmsatz
   */
  updateUmsatz(
    umsatz: UmsatzDTO,
    bearbeitet: boolean,
    onSuccess?: () => void,
    onError?: () => void,
  ): Observable<any> {
    /*
     * INFO: Den Status des Umsatzes hier bewusst zu manipulieren,
     * ist ein wichtiger Bestandteil für die Funktionalität der App.
     *
     * Damit die Umsätze unter "Umsatz zu bearbeiten" stehen bleiben,
     * bis der Anwender diesen mit dem Haken abschließt,
     * setzen wir den Status des Umsatzes bewusst auf "Unbearbeitet".
     * Obwohl sich der Umsatz wohlwissen in Bearbeitung befindet
     * und gegebenenfalls bereits mehrere Belege zugewiesen wurden.
     *
     * Dies ist nötig, damit das Kennzeichen "Abgeschlossen" im Backend korrekt berechnet werden kann.
     *
     * Ausnahme:
     * Werden einem Umsatz, durch den Anwender, alle Belege entfernt,
     * beispielsweise weil falsche Belege automatisiert zugewiesen wurden,
     * so kann der Anwender diesen nicht abschließen - da nichts abzuschließen ist.
     * In diesem Fall muss der Status des Umsatzes auf "Bearbeitet" gesetzt werden,
     * obwohl keine Belege zugewiesen wurden.
     */
    umsatz.bearbeitet = bearbeitet;

    let belegIds: string[] = [];
    if (umsatz.belege) {
      belegIds = umsatz.belege.map(beleg => beleg.id);
    }

    const updateUmsatzRequestDTO: UpdateUmsatzRequestDTO = {
      /*
       * INFO: Bei dem Aktualisieren des Kontoumsatzes, den Status immer auf "Bearbeitet" setzen,
       * wenn die Belege durch den Anwender bewusst entfernt wurden.
       *
       * Ansonsten den entsprechenden Status setzen, siehe Kommentar oben.
       */
      bearbeitet: (belegIds.length === 0 && !umsatz.ohneBeleg) ? true : bearbeitet,

      // INFO: Bei bearbeiteten Umsätzen muss zurKlaerung immer false gesetzt werden.
      zurKlaerung: bearbeitet ? false : undefined,

      belegIds,
      ohneBeleg: umsatz.ohneBeleg,
      kommentar: umsatz.kommentar,
    };

    return this.umsatzService.updateUmsatz(umsatz.inhaberId, umsatz.id, updateUmsatzRequestDTO)
      .pipe(
        takeUntil(this.unsubscribe$),
        tap(() => {
          if (onSuccess) {
            onSuccess();
          }
        }, error => {
          let errorMsg: string;

          switch (error.status) {
            case 404: {
              errorMsg = 'Betrieb konnte nicht gefunden werden.';
              break;
            }
            case 423: {
              errorMsg = 'Der Datensatz wird durch einen Anwender in ADNOVA+ gesperrt.';
              break;
            }
            default: {
              errorMsg = 'Speichern des Umsatzes fehlgeschlagen';
            }
          }

          this.snackBar.open(errorMsg, undefined, {duration: 5000, panelClass: 'error'});

          if (onError) {
            onError();
          }
        }),
        finalize(() => {
          this.updatedUmsatz$.next(umsatz);
        }),
      );
  }

  triggerUpdateUmsatz(
    umsatz: UmsatzDTO,
    bearbeitet: boolean = true,
    onSuccess?: () => void,
    onError?: () => void,
  ): Subscription {
    return this.updateUmsatz(
      umsatz,
      bearbeitet,
      onSuccess,
      onError,
    ).subscribe();
  }

  refresh(): void {
    this.filter$.next(this.filter$.getValue());
  }

  refreshCount(): void {
    this.countUmsaetze(this.filter$.value).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(count => {
      this.umsaetzeCount$.next(count);
    });
  }

  /**
   * Updated den Abgeschlossen-Filter
   *
   * @param abgeschlossen = Der neue Filter
   */
  updateFilterAbgeschlossen(
    abgeschlossen?: boolean,
  ): void {
    const oldValue = this.filter$.value;
    if (oldValue.filter.abgeschlossen !== abgeschlossen) {
      const newUmsatzFilter = this.deepCopyFilter(oldValue);
      newUmsatzFilter.filter.abgeschlossen = abgeschlossen;

      this.filter$.next(newUmsatzFilter);
    }
  }

  /**
   * Updated den Abgeschlossen-Filter
   *
   * @param zurKlaerung = Der neue Filter
   */
  updateFilterZurKlaerung(
    zurKlaerung?: boolean,
  ): void {
    const oldValue = this.filter$.value;
    if (oldValue.filter.zurKlaerung !== zurKlaerung) {
      const newUmsatzFilter = this.deepCopyFilter(oldValue);
      newUmsatzFilter.filter.zurKlaerung = zurKlaerung;

      this.filter$.next(newUmsatzFilter);
    }
  }

  /**
   * Updated das pagaeble im UmsatzFilter
   *
   * @param pageable = Das neue Pagaeble
   */
  updateFilterPageable(
    pageable: Pageable,
  ): void {
    const oldValue = this.filter$.value;
    if (oldValue.pageable.pageSize !== pageable.pageSize ||
      oldValue.pageable.pageIndex !== pageable.pageIndex) {

      const newUmsatzFilter = this.deepCopyFilter(oldValue);
      newUmsatzFilter.pageable = {
        ...pageable,
      };

      this.filter$.next(newUmsatzFilter);
    }
  }

  /**
   * Updated den Inhaber im UmsatzFilter
   *
   * @param inhaberId = Die neue Inhaber Id
   */
  updateFilterInhaber(
    inhaberId: string,
  ): void {
    const oldValue = this.filter$.value;
    if (oldValue.inhaberId !== inhaberId) {
      const matchingInhabers = this.inhabers$.value.filter(inhaber => inhaber.id === inhaberId);
      if (matchingInhabers.length > 0) {
        const newUmsatzFilter = this.deepCopyFilter(oldValue);
        newUmsatzFilter.inhaberId = inhaberId;

        this.filter$.next(newUmsatzFilter);
      }
    }
  }

  /**
   * Updated das FilterUmsatzDTO im UmstatzFilter
   *
   * @param filterUmsatzDTO = Das neue FilterUmsatzDTO
   */
  updateFilterUmsatzDTO(
    filterUmsatzDTO: FilterUmsatzDTO,
  ): void {
    const oldValue = this.filter$.value;
    if (oldValue.filter !== filterUmsatzDTO) {
      const newUmsatzFilter = this.deepCopyFilter(oldValue);
      newUmsatzFilter.filter = filterUmsatzDTO;

      this.filter$.next(newUmsatzFilter);
    }
  }

  changeSorting(sorting: PageableUmsatzDTOSortingEnum): void {
    this.filter$.next({
      ...this.filter$.value,
      sorting
    });
  }

  /**
   * Erzeugt eine Kopie des UmsatzFilters
   *
   * @param filter = Der entsprechende UmsatzFilter
   */
  private deepCopyFilter(
    filter: UmsatzFilter,
  ): UmsatzFilter {
    return {
      ...filter,
      filter: {
        ...filter.filter,
      },
      pageable: {
        ...filter.pageable,
      },
    };
  }

  // properties
  get inhaberId(): Observable<string | undefined> {
    return this.navigationService.currentInhaber$
      .pipe(
        filter(value => !!value),
        map(value => value?.id),
      );
  }

  get umsaetze(): Observable<UmsatzDTO[]> {
    return this.umsaetze$.asObservable();
  }

  get nextUmsaetze(): Observable<UmsatzDTO[]> {
    return this.nextUmsaetze$.asObservable();
  }

  get countedUmsaetze(): Observable<number> {
    return this.umsaetzeCount$.asObservable();
  }

  get pageable(): Observable<Pageable> {
    return this.filter$.pipe(
      map(filter => filter.pageable),
    );
  }

  get sorting(): PageableUmsatzDTOSortingEnum {
    return this.filter$.value.sorting;
  }
}
