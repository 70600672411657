/**
 * Kontoumsatzassistent
 * Die Kontoumsätze eines Bankkontos gelangen von der jeweiligen Bank der Anwender über die Datendrehscheibe in eine Datenbank und können von dort abgefragt werden. Den Kontoumsätzen können automatisch Belege zugeordnet werden. Gibt es keinen Umsatz der genau passt, werden dem Anwender Vorschläge gemacht aus denen manuell ausgewählt werden kann. Die Funktionen des Moduls Kontoumsatzassistent in ADNOVA+ werden mit Hilfe des KoAs-Services für das Portal zur Verfügung gestellt. 
 *
 * The version of the OpenAPI document: 1.1.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BasePageableDTO } from './base-pageable-dto';
import { PageableUmsatzAllOfDTO } from './pageable-umsatz-all-of-dto';


/**
 * Gibt das OffSet, Limit und die Sortierung für das Abrufen von Kontoumsätzen über mehrere Seiten an
 */
export interface PageableUmsatzDTO { 
    /**
     * Gibt an bei welchem Datensatz die Abfrage beginnen soll
     */
    offset?: number;
    /**
     * Begrenzt die Anzahl der abgefragten Datensätzen
     */
    limit?: number;
    /**
     * Bestimmt die Sortierung der Kontoumsätze
     */
    sorting?: Array<PageableUmsatzDTOSortingEnum>;
}
export enum PageableUmsatzDTOSortingEnum {
    Erstelltasc = 'ERSTELLTASC',
    Erstelltdesc = 'ERSTELLTDESC',
    Geaendertasc = 'GEAENDERTASC',
    Geaendertdesc = 'GEAENDERTDESC',
    Datumasc = 'DATUMASC',
    Datumdesc = 'DATUMDESC',
    Nummerasc = 'NUMMERASC',
    Nummerdesc = 'NUMMERDESC',
    Betragasc = 'BETRAGASC',
    Betragdesc = 'BETRAGDESC',
    Verwendungszweckasc = 'VERWENDUNGSZWECKASC',
    Verwendungszweckdesc = 'VERWENDUNGSZWECKDESC'
};



