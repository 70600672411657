import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {PageableUmsatzDTOSortingEnum} from '../../../../openapi/kontoumsatz-openapi';
import {FormControl} from '@angular/forms';
import {debounceTime, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';


@Component({
  selector: 'ku-sorting',
  templateUrl: './sorting.component.html',
  styleUrls: ['./sorting.component.scss']
})
export class SortingComponent implements OnInit, OnDestroy {

  @Input()
  public set sorting(sorting: PageableUmsatzDTOSortingEnum | undefined) {
    this.controlSorting.setValue(
      sorting,
      {emitEvent: false}
    );
  }

  @Output()
  public sortingChange = new EventEmitter<PageableUmsatzDTOSortingEnum>();

  protected readonly PageableUmsatzDTOSortingEnum = PageableUmsatzDTOSortingEnum;

  public controlSorting = new FormControl();

  private readonly unsubscribe$ = new Subject<void>();

  ngOnInit(): void {
    this.controlSorting.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(250),
    ).subscribe(value => {
      this.sortingChange.emit(value);
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
