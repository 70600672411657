<mat-form-field appearance="outline">
  <mat-label>Bank</mat-label>
  <mat-select [formControl]="control" multiple>
    <div
      (mouseenter)="cancelCloseOptions()"
      (mouseleave)="closeOptions()"
    >
      <mat-option *ngFor="let option of selectOptions" [value]="option.bankkontoId">
        {{option.display}}
      </mat-option>
    </div>
  </mat-select>
</mat-form-field>
